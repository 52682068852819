html {
  box-sizing: border-box;
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;
}

body {
  color: #252534;
  font-family: Flama, Helvetica, Arial, sans-serif;
  margin: 0;
  padding-bottom: 8rem;
}

@media (max-width: 800px) {
  body {
    padding-bottom: 6rem;
  }
}

abbr[title] {
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
  font-size: 1.6rem;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

a {
  color: #6600ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1 {
  font-family: "Flama Semicondensed", Helvetica, Arial, sans-serif;
  font-size: 6.4rem;
  font-weight: 800;
  margin: 0;
  margin-bottom: 2rem;
  max-width: 980px;
}

@media (max-width: 800px) {
  h1 {
    font-size: 4rem;
  }
}

h2 {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.3;
  padding-top: 5.6rem;
  margin-top: -2.6rem;
  max-width: 980px;
}

@media (max-width: 800px) {
  h2 {
    font-size: 2.8rem;
  }
}

h3 {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 1rem;
  padding-top: 7.5rem;
  margin-top: -4.2rem;
  border-bottom: 1px solid #c1c1c6;
}

@media (max-width: 800px) {
  h3 {
    font-size: 2.4rem;
  }
}

h4 {
  font-family: "Flama Semicondensed", Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: 500;
  padding-top: 7rem;
  margin-top: -2rem;
}

@media (max-width: 800px) {
  h4 {
    font-size: 2.2rem;
  }
}

h5 {
  font-size: 2.1rem;
  font-weight: 600;
  margin: 0;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

@media (max-width: 800px) {
  h5 {
    font-size: 1.7rem;
  }
}

h6 {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 2rem;
}

p,
ul,
ol {
  font-size: 2.1rem;
  line-height: 1.33;
  margin: 0;
  margin-bottom: 1.7rem;
}

@media (max-width: 800px) {
  p,
  ul,
  ol {
    font-size: 1.7rem;
  }
}

ul,
ol {
  padding-left: 2.2rem;
}

ol li {
  list-style-type: none;
}

ul > li::before {
  content: none;
}

ul {
  border-left-style: solid;
  border-color: #c1c1c6;
  border-width: 2px;
}

:global .large,
:global .large p {
  font-family: "Flama Semicondensed", Helvetica, Arial, sans-serif;
  font-size: 3.6rem;
  font-weight: normal;
  margin-bottom: 3rem;
}

@media (max-width: 800px) {
  :global .large,
  :global .large p {
    font-size: 2.4rem;
  }
}

:global .micro,
:global .micro p {
  color: #767678;
  font-size: 1.4rem;
}

ul {
  list-style: none;
}

li {
  margin-bottom: 1.7rem;
  position: relative;
}

li::before {
  color: #e6e6e6;
  content: "\2022";
  font-size: 3.5rem;
  position: absolute;
  top: -1.1rem;
  left: -2.6rem;
}

@font-face {
  font-family: "Flama";
  src: url("../fonts/Flama-Book.woff2") format("woff2"),
    url("../fonts/Flama-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Flama";
  src: url("../fonts/Flama-BookItalic.woff2") format("woff2"),
    url("../fonts/Flama-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Flama";
  src: url("../fonts/Flama-Semibold.woff2") format("woff2"),
    url("../fonts/Flama-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Flama Semicondensed";
  src: url("../fonts/FlamaSemicondensed-Book.woff2") format("woff2"),
    url("../fonts/FlamaSemicondensed-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Flama Semicondensed";
  src: url("../fonts/FlamaSemicondensed-Medium.woff2") format("woff2"),
    url("../fonts/FlamaSemicondensed-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Flama Semicondensed";
  src: url("../fonts/FlamaSemicondensed-Black.woff2") format("woff2"),
    url("../fonts/FlamaSemicondensed-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
